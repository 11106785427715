.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #223f21;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  text-align: justify;
  text-indent: 40px;
}

.image {
  cursor: zoom-in;
  width: 100%;
}

.zoomed {
  border: solid;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  cursor: zoom-out;
  z-index: 20000;
  width: 80%;
  height: 80%;
}

.hidenSVG {
  cursor: pointer;
}

.hidenSVG>div {
  width: 24px;
}

.hidenSVG>div>svg {
  visibility: hidden;
}

.hidenSVG:hover>div>svg {
  visibility: visible;
}

.hidenSVG>a {
  text-decoration: none;
  color: black;
}

.hidenSVG:hover>a {
  text-decoration: underline;
}

.on-top {
  position: fixed;
  top:0px;
  left:0px;
  background-color: #808080;
  width: 200%;
  height: 200%;
  z-index: 10000;
  opacity: 75%;
  max-width:100% !important;
}

.on-top-btn {
  z-index: 11000;
}

.btn-continue {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 1.42857;
}

.btn-pause {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 1.42857;
}
.btn-pause img, .btn-continue img {
  margin-top: -12px;
  margin-left: -8px;
}

.big-font {
  font-size: 1.4rem;
}

.small-font {
  font-size: 1.1rem;
}

.noScroll {
  overflow: hidden;
}

.text-justify {
  text-align: justify;
}

.background-grey {
  background-color: #E8E8E8;
}

.nav-link {
  color:#ffffff;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:active{
  background-color: #343a40 !important;
  color:#dee2e6;
}

.highlightedBlue .accordion-button.collapsed {
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width)*-1) 0 var(--bs-accordion-border-color);
  color: var(--bs-accordion-active-color);
}

.up-button {
  position:fixed;
  top: 85%;
  left: -42%;
  pointer-events: none;
}

.up-button-hided {
  position:fixed;
  top: 85%;
  left: -42%;
  z-index: 10000;
  display: none;
  pointer-events: none;
}
.full-height {
  height: 100%;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 80%;
}

.image-tool__image-picture { margin: auto;}
.image-tool__caption { display: none; }
.cdx-nested-list__item {text-align: left;}

.cdx-settings-button[data-tune='withBorder'],
.cdx-settings-button[data-tune='withBackground'],
.cdx-settings-button[data-tune='stretched'] {
  display: none;
}

.green-nav
{
  color: greenyellow !important;
}

.yellow-nav {
  color: yellow !important;
}

.on-super-top
{
  z-index: 100000 !important;
}
.order-item {
  border: solid #1a1e21;
  border-radius: 5px;
  margin: 10px 10px 10px 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
  padding: 10px 10px 10px 10px;
}

.bg-red {
  background-color: #fcd3d3;
}

.bg-green {
  background-color: #e7fcd3;
}

.paper-container {
  position:relative;
}
.paper-content-left {
  position: absolute;
  left: 45px;
}
.paper-content-center {
  position: absolute;
  left: 83px;
  top: -26px;
}
.paper-content-right {
  position: absolute;
  left: 111px;
  top: -26px;
}
.paper-content-point {
  width: 16px;
  height: 18px;
}
.paper-content-point-center-left {
  margin-left: -8px;
}
.paper-content-point-center-right {
  margin-left: 8px;
}
.paper-content-point-bottom-left {
  margin-left: -16px;
}
.paper-content-point-bottom-right {
  margin-left: 16px;
}
.paper-content-label {
  margin-top: 16px;
  font-size: 9pt;
  text-align: center;
}

.scrolled  {
  overflow-y: scroll;
  height: 70vh;
  padding-left:0 !important;
  padding-right:0 !important;
}